
import { EmployeeClient } from "@/api/server";
import { AxiosResponse } from "axios";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataOptions } from "vuetify";
@Component
export default class EmployeeList extends Vue {
  private data_items: Array<unknown[]> = [];
  options: DataOptions = {
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: true,
    mustSort: false,
  };
  public headers = [
    { text: "First Name", value: "first_name" },
    { text: "Last Name", value: "last_name" },
    { text: "Email", value: "email" },
    { text: "Office", value: "phone_office" },
    { text: "Fax", value: "phone_fax" },
    { text: "Cell", value: "phone_cell" },
    { text: "Home", value: "phone_home" },
    { text: "Title", value: "title" },
  ];
  public params = {};
  loading = false;
  search = "";
  totalItems = 0;
  pageCount = 1;

  created(): void {
    this.getData();
  }

  @Watch("options")
  private async getData() {
    this.params = {
      page: this.options.page,
      per_page: this.options.itemsPerPage,
      search: this.search ? this.search.trim().toLowerCase() : null,
      sort_by: this.options.sortBy,
      sort_desc: this.options.sortDesc,
    };
    this.loading = true;
    await EmployeeClient.getEmployees(this.params)
      .then((response: AxiosResponse) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let items = response.data.data;
        this.data_items = items;
        this.totalItems = response.data.total;
        /*console.log(response.data.data, response.data);*/
        // const total = items.length;
        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort(
            (a: { [x: string]: any }, b: { [x: string]: any }) => {
              const sortA = a[sortBy[0]];
              const sortB = b[sortBy[0]];

              if (sortDesc[0]) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            }
          );
        }
        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
  }
}
